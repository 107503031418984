<template>
    <footer
        class="footer"
        :class="{ 'footer--dark': dark }"
    >
        <div class="footer__row">
            <div class="footer__row__logo">
                <a
                    v-show="showPoweredBy"
                    target="_blank"
                    :href="whitelabelWebsiteUrl"
                >
                    <span>{{ $t('shop.components.footer.powered_by') }}</span>
                    <Logo />
                </a>
            </div>
            <div
                v-show="showLocaleSwitch"
                class="footer__row__locale"
                role="button"
                tabindex="0"
                @click="otLocaleSwitch.toggle()"
                @keydown.space="otLocaleSwitch.toggle()"
                @keydown.enter="otLocaleSwitch.toggle()"
            >
                <OtLocaleFlag
                    :locale="$localization.locale"
                    size="short"
                />

                <i
                    class="oti is-small"
                    :class="localeSwitchOpen ? 'oti-drop-up' : 'oti-drop-down'"
                />
            </div>
        </div>

        <OtLocaleSwitch
            ref="ot-locale-switch"
            class="footer__locale-switch"
            @opened="openLocaleSwitch"
        />
    </footer>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Ref } from 'vue-property-decorator';
import OtLocaleFlag from '@openticket/vue-localization/src/components/OtLocaleFlag.vue';
import OtLocaleSwitch from '@openticket/vue-localization/src/components/OtLocaleSwitch.vue';

@Component({
    components: {
        OtLocaleFlag,
        OtLocaleSwitch,
    },
})
export default class Footer extends Vue {

    @Prop({ type: Boolean })
        dark!: boolean;

    @Prop({ default: true, type: Boolean })
        showPoweredBy!: boolean;

    @Prop({ default: true, type: Boolean })
        showLocaleSwitch!: boolean;

    @Ref('ot-locale-switch')
        otLocaleSwitch!: OtLocaleSwitch;

    localeSwitchOpen = false;

    get whitelabelWebsiteUrl(): string {
        return this.$whitelabel.website_url;
    }

    openLocaleSwitch(opened: boolean): void {
        this.localeSwitchOpen = opened;
    }

}
</script>

<style lang="scss" scoped>
.footer {
    &__row {
        margin: 0.75rem 0;
        text-align: left;
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;

        & > * {
            margin: 0.75rem 0;
        }

        &__logo {
            position: relative;
            z-index: 5;

            a {
                color: inherit;
                text-decoration: none;
            }

            span,
            img {
                vertical-align: middle;
            }

            img {
                height: 1.25rem;
            }

            span {
                margin-right: 0.5rem;
                color: var(--color-canvas-invert);
                font-size: 0.75rem;
            }
        }

        &__locale {
            display: flex;

            cursor: pointer;

            .oti {
                margin-left: 0.5rem;
            }
        }
    }

    &__locale-switch {
        margin: var(--ot-spacing-default, 1rem) 0;
    }

    &--dark & {
        &__logo {
            span {
                color: var(--color-card-dark-invert);
            }
        }
    }
}

@media (max-width: 25rem) {
    .footer__row {
        flex-direction: column;
    }
}
</style>
