<template>
    <div
        id="app"
        class="order-view"
    >
        <div v-if="initialized">
            <router-view />

            <OrderFooter />
        </div>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';

import { Inject, InjectReactive } from 'vue-property-decorator';
import { type LoadOptions, RudderAnalytics } from '@rudderstack/analytics-js';
import {
    type CookiePreferences,
    OpenTicketCookiesInjector,
} from '@openticket/lib-cookies';
import type { IOrderConfig } from '@openticket/lib-order';
import { Level } from '@openticket/lib-sdk-helpers';
import { setValidationLang } from '../../utils/sdk/metadata';
import VueError from '../../error';

import OrderFooter from './components/OrderFooter.vue';
import type { BaseInit, OverlayManager } from '../../types';

type CookiesModalConfigTexts = {
    landing?: {
        title?: string;
        introduction?: string;
        link?: {
            title?: string;
            text?: string;
        };
        actions?: {
            accept?: {
                title?: string;
                text?: string;
            };
        };
    };
    preferences?: {
        introduction?: string;
        labels?: {
            saveOnDevice?: string;
            baseAds?: string;
            personalisedAds?: string;
        };
        actions?: {
            acceptAll?: {
                title?: string;
                text?: string;
            };
            accept?: {
                title?: string;
                text?: string;
            };
        };
    };
}

@Component({ components: { OrderFooter } })
export default class OrderView extends Vue {

    config!: IOrderConfig;

    @Inject('overlay')
        overlay!: OverlayManager;

    @Inject('baseInit')
        baseInit!: BaseInit;

    @InjectReactive('localizationInitPromise')
        localizationInitPromise!: Promise<void>;

    cookies = new OpenTicketCookiesInjector();
    rudderAnalytics = new RudderAnalytics();

    initialized = false;

    async created(): Promise<void> {
        this.$localization.on('locale-change', (locale: string) => {
            setValidationLang(locale);
        });

        await Promise.all([ this.initCookies(), this.initOrder() ]);
    }

    async initOrder(): Promise<void> {
        const closeOverlay: () => void = this.overlay.show();

        try {
            this.config = {
                baseUrl: import.meta.env.VITE_SHOP_API_URL,
                orderGuid: this.$route.params.order_id,
            };

            if (import.meta.env.VITE_LOGGER_URL) {
                this.config.loggingUrl = import.meta.env.VITE_LOGGER_URL;
                this.config.loggingLevel = Level.Debug;
            }

            await this.$order.init(this.config);

            Vue.observable(this.$order.data);

            const { shop } = this.$order.data;

            await this.baseInit(shop.guid);

            setValidationLang(this.$localization.locale.locale);

            if (this.$whitelabel.shop.gtm_code) {
                this.$order.tracking.addGTM(this.$whitelabel.shop.gtm_code);
            }

            if (
                this.$whitelabel.shop.rudderstack_data_plane_url
                && this.$whitelabel.shop.rudderstack_write_key
            ) {
                const rudderstackLoadOptions: Partial<LoadOptions> = {
                    sendAdblockPage: true,
                    // These settings should be amended when all projects are using 3.x
                    // https://www.rudderstack.com/docs/sources/event-streams/sdks/rudderstack-javascript-sdk/migration-guide/#migration-considerations
                    storage: {
                        encryption: {
                            version: 'legacy',
                        },
                        migrate: false,
                    },
                    plugins: [
                        'GoogleLinker',
                        'NativeDestinationQueue',
                        'StorageEncryptionLegacy',
                        'StorageEncryption',
                        'StorageMigrator',
                        'DeviceModeDestinations',
                    ],
                };

                if (this.$whitelabel.shop.rudderstack_cdn_url) {
                    rudderstackLoadOptions.pluginsSDKBaseURL = this.$whitelabel.shop.rudderstack_cdn_url;
                }
                if (this.$whitelabel.shop.rudderstack_config_url) {
                    rudderstackLoadOptions.configUrl = this.$whitelabel.shop.rudderstack_config_url;
                }

                this.rudderAnalytics.load(
                    this.$whitelabel.shop.rudderstack_write_key,
                    this.$whitelabel.shop.rudderstack_data_plane_url,
                    rudderstackLoadOptions,
                );

                // Make sure to remove anonymous_id from url when present
                this.rudderAnalytics.ready(() => {
                    void this.$router.replace({ query: { ajs_aid: undefined } });
                });

                // Make sure to reset all traits and start clean (only leave anonymousId intact)
                this.rudderAnalytics.reset();

                // Set new trait with current shop_id
                this.rudderAnalytics.page(
                    'order',
                    'order',
                    {},
                    {
                        context: {
                            traits: {
                                order_id: this.$order.data.guid,
                                shop_id: this.$order.data.shop_id,
                            },
                        },
                    },
                );
            }

            if (
                shop.google_tag
                && this.cookies.initialized
                && this.cookies.preferences
                && this.cookies.preferences.baseAds
            ) {
                // At this point, we can not rely on cookies triggering a consent update to add the shop's GTM container.
                // If the cookies are accepted later, this is OK, the accept listener will try to add it again.
                this.$order.tracking.addGTM(shop.google_tag);
            }

            try {
                if (this.$route.params.status === 'paid') {
                    this.$order.sendLegacyEvents();
                } else {
                    this.$order.sendLegacyEventsWithoutConversion();
                }
            } catch (e) {
                // No-op - Tracking failures should not break flow!
                console.error('[initOrder] Sending events failed', { e });
            }

            // Set document title
            if (shop.name) {
                window.document.title = shop.name;
            }

            window.IsAppleDevice = this._isAppleDevice();

            if (this.$route.query.sessionId) {
                void this.$router.replace({
                    path: this.$route.path,
                    query: {
                        ...this.$route.query,
                        sessionId: undefined,
                    },
                });
            }
        } catch (e) {
            if (!e || typeof e !== 'object' || !('isOpenTicketError' in e) || !e.isOpenTicketError) {
                if (e instanceof Error) {
                    this.$logger.log(new VueError('initOrder:', e));
                }
            }

            void this.$router.push({
                name: 'error',
                query: {
                    redirect: this.$route.path,
                },
            });
        } finally {
            this.initialized = true;

            closeOverlay();
        }
    }

    async initCookies(): Promise<void> {
        await this.localizationInitPromise;

        const prefs = await this.cookies.init({
            modal: {
                texts: this.$te('common.cookie_modal')
                    ? this.$t('common.cookie_modal') as CookiesModalConfigTexts
                    : {},
            },
        });

        this.cookies.on('accept', (_, newPreferences?: CookiePreferences) => {
            this.cookiePreferencesUpdated(newPreferences);
        });

        this.cookies.on('clear', (_, newPreferences?: CookiePreferences) => {
            this.cookiePreferencesUpdated(newPreferences);
        });

        // Apply preferences or request if not available
        if (prefs) {
            this.cookiePreferencesUpdated(prefs);
        } else {
            await this.cookies.openCookiesModal();
        }
    }

    cookiePreferencesUpdated(preferences?: Partial<CookiePreferences>): void {
        // Cookie preferences can be updated when:
        // - Saved preferences are retrieved throught the cookie client;
        // - The user has manually updated (a subset of) cookie preferences;

        // The preferences can be individually present in the preferences object.
        // Personalised ads will only be accepted when both the base ads and
        // personalized ads properties are set to true.
        if (!preferences) {
            this.$order.tracking.revokeConsent();

            return;
        }

        // When base ads are accepted, the order is initialized and its shop has a GTM code,
        // the shop specific GTM container is loaded.
        //
        // DD-SHOP-2714
        //
        // The addGTM method can be triggered as often as convenient.
        // The method will silently ignore GTM codes which were already succesfully registered.
        if (
            preferences.baseAds
            && this.$order.initialized
            && this.$order.data.shop.google_tag
        ) {
            // Ignored when the order has not finished loading
            // This is OK -> After the order is initialized, it is tried as well.
            this.$order.tracking.addGTM(this.$order.data.shop.google_tag);
        }

        this.$order.tracking.updateConsent(preferences);
    }

    _isAppleDevice(): boolean {
        try {
            return (
                [
                    'iPad Simulator',
                    'iPhone Simulator',
                    'iPod Simulator',
                    'iPad',
                    'iPhone',
                    'iPod',
                ].includes(navigator.platform)
                // iPad on iOS 13 detection
                || (navigator.userAgent.includes('Mac')
                    && 'ontouchend' in document)
            );
        } catch {
            return false;
        }
    }

}
</script>

<style lang="scss" scoped>
.order-view {
    text-align: center;

    &__retry-polling {
        margin-bottom: 1rem;
    }

    &__download-all {
        margin-bottom: 1rem;
    }

    &__footer {
        &__text {
            max-width: 24rem;
            margin: 1rem auto;
        }
    }
}
</style>
