<template>
    <div id="app">
        <div class="app">
            <div class="app__container">
                <router-view />
            </div>
            <SplashScreen
                :visible="overlayManager.visible"
                :label="overlayManager.visible ? overlayManager.label : null"
            />
        </div>
    </div>
</template>

<script lang="ts">
import axios from 'axios';
import Vue from 'vue';
import Component from 'vue-class-component';
import type { PreferredLocales } from '@openticket/vue-localization';
import { Provide, ProvideReactive } from 'vue-property-decorator';
import type { StyleTheme } from '@openticket/lib-style';
import { initCustomTranslations } from './services/localization';
import type { OverlayManager } from './types';

@Component
export default class Base extends Vue {

    @Provide('overlay')
        overlayManager: OverlayManager = {
            label: null,
            visible: false,
            show: (label?: string) => {
                this.overlayManager.label = label || null;
                this.overlayManager.visible = true;

                return () => {
                    this.overlayManager.visible = false;
                    this.overlayManager.label = null;
                };
            },
        };

    @ProvideReactive('baseInitialized')
        baseInitialized = false;

    @ProvideReactive('localizationInitPromise')
        localizationInitPromise!: Promise<void>;

    whitelabelInitPromise!: Promise<void>;

    async created(): Promise<void> {
        this.localizationInitPromise = this.initLocalization();
        this.whitelabelInitPromise = this.initWhitelabel();

        await Promise.all([
            this.localizationInitPromise,
            this.whitelabelInitPromise,
        ]);

        this.baseInitialized = true;
    }

    async initWhitelabel(): Promise<void> {
        await this.$whitelabel.init();
        Vue.observable(this.$whitelabel);
    }

    async initShopSettings(shopId: string): Promise<void> {
        if (!this.$settings) {
            return;
        }

        const disableCache = this.$route.query.nocache !== undefined;

        await this.$settings.init({
            baseUrl: import.meta.env.VITE_SHOP_SETTINGS_API_URL,
            disableCache,
            shopId,
            httpRead: axios.create(),
        });

        // Set custom style props
        if (this.$settings.static && this.$settings.static.style) {
            this.$style.setStyle(this.$settings.static.style);
        }

        // Support dark mode via ?theme=dark query param
        const query = new URLSearchParams(window.location.search);
        const queryTheme = query.get('theme');

        // Only set shop settings theme if no queryTheme is provided
        if (queryTheme) {
            this.$style.setTheme(queryTheme as StyleTheme);
        } else if (this.$settings.static && this.$settings.static.theme) {
            this.$style.setTheme(
                this.$settings.static
                    && (this.$settings.static.theme as StyleTheme),
            );
        }
    }

    async initLocalization(): Promise<void> {
        this.$localization.on('locale-change', (locale: string) => {
            void (async () => {
                // Note that initial setting of the locale is done separately,
                // as the whitelabel services might not be initialized yet at this point!
                try {
                    await this.whitelabelInitPromise;
                    this.$whitelabel.setLocale(locale);
                } catch (e) {
                    // No-op -> Should not stop initialization flow! -> TODO Maybe log??
                    console.error(e);
                }
            })();
        });

        const preferredLocales: PreferredLocales = this.$localization.preferredLocales();

        const localeGetParamOverwrite = this.$route.query.locale as
            | string
            | null;

        await this.$localization.init({
            locale: {
                defaults: [
                    localeGetParamOverwrite,
                    preferredLocales.userOverwrite,
                    preferredLocales.browser,
                ],
            },
        });
    }

    @Provide('baseInit')
    async baseInit(shop_id: string): Promise<void> {
        await this.localizationInitPromise;

        await Promise.all([
            this.initShopSettings(shop_id),
            this.whitelabelInitPromise,
            initCustomTranslations(this.$localization, shop_id),
        ]);
    }

}
</script>

<style lang="scss">
@import './assets/scss';

.app {
    padding: 2.5rem 0.5rem;
    box-sizing: border-box;
    transition: var(--ot-transition-default);

    @include mobile {
        padding: 1.5rem 0.5rem;
        padding-bottom: 1rem;
        padding-top: 2rem;
    }

    &__container {
        margin: 0 auto;
        max-width: var(--ot-shop-layout-width);
    }
}
</style>
