<template>
    <div class="order-details-ticket">
        <div
            v-if="qrLoaded && qrUrl"
            class="order-details-ticket__image"
        >
            <img
                :src="qrUrl"
                :alt="ticket.ticket_number"
            >
        </div>

        <div class="order-details-ticket__content">
            <h6>{{ ticket.ticket.name }}</h6>
            <div
                v-if="qrLoaded && qrUrl"
                class="ot-text-tiny"
            >
                {{ ticket.ticket_number }}
            </div>
            <div
                :key="
                    `order-details-ticket__content.ticket.finn_price.${ticket.finn_price}`
                "
                class="ot-text-tiny"
            >
                {{
                    $l.currency(
                        ticket.finn_price -
                            ticket.finn_service_fee,
                        $order.data.shop.currency
                    )
                }}
                <span
                    v-if="ticket.finn_service_fee"
                    :key="
                        `order-details-ticket__content`+
                            `.ticket.finn_service_fee${ticket.finn_service_fee}`
                    "
                >
                    +
                    {{
                        $l.currency(
                            ticket.finn_service_fee,
                            $order.data.shop.currency
                        )
                    }}
                    {{
                        $t(
                            'order.components.order_details.tickets.service_fee'
                        )
                    }}
                </span>
            </div>
        </div>
        <div class="order-details-ticket__price">
            <h6
                :key="
                    `order-details-ticket__price.ticket.finn_price.${ticket.finn_price}`
                "
            >
                {{
                    $l.currency(
                        ticket.finn_price,
                        $order.data.shop.currency
                    )
                }}
            </h6>
        </div>
    </div>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator';
// TODO: Uncomment when @openticket/lib-log is available
// import { Log, send } from '@openticket/lib-log';
// import { IOrderTicket, StringMessage } from '@openticket/lib-order';
// TODO: Remove when @openticket/lib-order is available
import type { IOrderTicket } from '@openticket/lib-order';
import QRCode from 'qrcode';

@Component({})
export default class OrderDetailsTicket extends Vue {

    @Prop({ default: false, type: Boolean })
        showQrCodes!: boolean;

    @Prop({ required: true })
        ticket!: IOrderTicket;

    private qrLoaded: boolean = false;
    private qrUrl: string = '';

    public created(): void {
        if (this.showQrCodes) {
            QRCode.toDataURL(this.ticket.ticket_number)
                .then((url) => {
                    this.qrUrl = url;
                    this.qrLoaded = true;
                })
                .catch((_) => {
                    // TODO: Uncomment when @openticket/lib-log is available
                    // send(
                    //     new StringMessage(
                    //         'error.order_details_ticket.qr_code_generation',
                    //         'Failed to generate QR code.',
                    //         { error: err },
                    //     ),
                    //     Log.Error,
                    // );
                });
        }
    }

}
</script>

<style scoped lang="scss">
.order-details-ticket {
    display: flex;
    border-bottom: 1px solid var(--ot-shop-color-box-accent);
    padding: 0.5rem 0;
    align-items: center;

    &:last-child {
        border-bottom: none;
    }

    &__image {
        flex: 0;
        width: 48px;
        height: 48px;
        image-rendering: pixelated;
        margin-right: 0.75rem;

        img {
            width: 48px;
            height: 48px;
        }
    }

    &__content {
        flex: 1;
    }

    &__price {
        display: inline-flex;
        align-items: center;
    }
}
</style>
