<template>
    <div class="contact-us">
        <Footer
            :show-powered-by="showPoweredBy"
            :show-locale-switch="showLocaleSwitch"
        />
        <br>
        <p
            v-show="showCopyright"
            class="ot-label"
        >
            {{
                $t('order.components.order_footer.copyright_text', {
                    company,
                    year,
                })
            }}
        </p>
        <br>
        <i18n
            v-if="url"
            v-show="showSupport"
            path="order.components.order_footer.contact_text_url"
            tag="p"
            class="ot-label"
        >
            <template #contactLink>
                <a :href="url">{{ strippedUrl }}</a>
            </template>
        </i18n>
        <i18n
            v-else
            v-show="showSupport"
            path="order.components.order_footer.contact_text"
            tag="p"
            class="ot-label"
        >
            <template #contactLink>
                <a :href="'mailto:' + email">{{ email }}</a>
            </template>
        </i18n>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';

@Component
export default class ContactSupport extends Vue {

    get company(): string {
        return this.$whitelabel.name;
    }

    get email(): string {
        return this.$whitelabel.order.support_email;
    }

    get url(): string | undefined {
        return this.$whitelabel.order.support_url;
    }

    get strippedUrl(): string | undefined {
        return (
            this.$whitelabel.order.support_url
            && this.$whitelabel.order.support_url.replace(/([?#]).+$/, '')
        );
    }

    get year(): string {
        return new Date().getFullYear().toString();
    }

    get showLogo(): boolean {
        return (
            !this.$settings
            || !this.$settings.static
            || !this.$settings.static.order
            || !this.$settings.static.order.header
            || this.$settings.static.order.header.showLogo
            || this.$settings.static.order.header.showLogo === null
        );
    }

    get showPoweredBy(): boolean {
        return (
            !this.$settings
            || !this.$settings.static
            || !this.$settings.static.order
            || !this.$settings.static.order.footer
            || this.$settings.static.order.footer.showPoweredBy
            || this.$settings.static.order.footer.showPoweredBy === null
        );
    }

    get showLocaleSwitch(): boolean {
        return (
            !this.$settings
            || !this.$settings.static
            || !this.$settings.static.order
            || !this.$settings.static.order.footer
            || this.$settings.static.order.footer.showLocaleSwitcher
            || this.$settings.static.order.footer.showLocaleSwitcher === null
        );
    }

    get showCopyright(): boolean {
        return (
            !this.$settings
            || !this.$settings.static
            || !this.$settings.static.order
            || !this.$settings.static.order.footer
            || this.$settings.static.order.footer.showCopyright
            || this.$settings.static.order.footer.showCopyright === null
        );
    }

    get showSupport(): boolean {
        return (
            !this.$settings
            || !this.$settings.static
            || !this.$settings.static.order
            || !this.$settings.static.order.footer
            || this.$settings.static.order.footer.showSupport
            || this.$settings.static.order.footer.showSupport === null
        );
    }

}
</script>

<style lang="scss" scoped>
.contact-us {
    text-align: center;
    margin-top: var(--ot-spacing-lg);
    padding: 0rem 0.875rem;

    > .ot-label {
        max-width: 24rem;
        margin: 0 auto;
    }
}
</style>
