import Vue from 'vue';
import { OrderClient } from '@openticket/lib-order';
import { Logger } from '@openticket/lib-sdk-helpers';
import { i18n } from '../../base';
import Base from '../../Base.vue';
import { validateMetadata } from '../../utils/sdk/metadata';
import router from './router';
import VueError from '../../error';

declare module 'vue/types/vue' {
    interface Vue {
        $order: OrderClient;
        $logger: Logger;
    }
}

declare global {
    interface Window {
        Order: OrderClient;
        IsAppleDevice: boolean | null;
    }
}

const sessionId = new URLSearchParams(window.location.search).get('sessionId') || undefined;

// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
const logger = (Vue.prototype.$logger = new Logger('order', {
    sessionId,
}));

// Add order SDK to vue prototype
// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
Vue.prototype.$order = window.Order = Vue.observable(
    new OrderClient({
        sessionId: logger.sessionId,
        validatorFn: validateMetadata,
    }),
);

new Vue({
    router,
    i18n,
    render: (h) => h(Base),
}).$mount('#app');

Vue.config.errorHandler = (err) => {
    logger.throw(new VueError('root', err));
};
